<template>
  <div class="logo">
    <div class="info-holder text-center">
      <router-link class="logo" to="/">
        <div class="info-holder text-center">
          <img src="@/assets/images/logo.png" alt="">
        </div>
      </router-link>
    </div>
  </div>
  <!-- content @s -->
  <div class="nk-content nk-content-lg nk-content-fluid">
    <div class="container-xl wide-lg">
      <div class="nk-content-inner">
        <div class="nk-content-body">
          <div class="nk-block-head nk-block-head-lg">
            <div class="nk-block-head-content">
              <div class="nk-block-head-content">
                <h2 class="nk-block-title fw-normal text-white text-uppercase">Ficha de Pago</h2>
              </div>
            </div>
          </div><!-- nk-block-head -->
          <div v-if="cargo.estatus" class="alert" :class="{'alert-danger': cargo.estatus === 'Fallido','alert-warning': cargo.estatus === 'Pendiente','alert-success': cargo.estatus === 'Pagado'}">
            {{cargo.message}}
            <ul class="text-dark">
              <li><strong>Folio:</strong> {{cargo.folio}}</li>
              <li><strong>ID Pago:</strong> {{cargo.uuid_pago}}</li>
              <li><strong>Fecha:</strong> {{cargo.fecha_f}}</li>
            </ul>
            <ul class="text-dark" v-if="cargo.estatus === 'Pagado'">
              <li><strong>Descripción:</strong> Compra Curso '{{model.titulo}}' en chio-moreno.com, incluye acceso online a curso impartido por ®Chio Moreno</li>
              <li><strong>Forma de Pago:</strong> {{cargo.forma_pago}}</li>
              <li><strong>Subtotal:</strong> {{cargo.monto}}</li>
              <li><strong>Comision:</strong> {{cargo.comision}}</li>
              <li><strong>Total:</strong> {{cargo.total}} {{cargo.moneda}}</li>
            </ul>
            <p>Recuerda estar al pendiente de tu teléfono y WhatsApp ya que en un lapso no mayor a 2 horas, nos estaremos comunicando contigo, para darte tus accesos personalizados y bienvenida al curso.</p>
          </div>
          <form @submit.prevent="save" class="form-pago nk-block invest-block">
            <div class="row g-gs">
              <div class="col-lg-7">
                <div class="card-js card card-bordered">
                  <input type="hidden" name="token_id" id="token_id">
                  <div class="nk-iv-wg4-sub d-flex align-items-center justify-between"><h6 class="m-0 nk-iv-wg4-title title">Curso: {{model.titulo}}</h6> <button v-if="paso === 'pago'" type="button" class="btn btn-gray btn-sm m-0" @click.prevent="setPaso('datos_cliente')"><em class="ni ni-arrow-left mr-1"></em> Regresar</button></div>
                  <transition name="slide-fade"
                              enter-active-class="animate__animated animate__bounceInRight"
                              leave-active-class="animate__animated animate__bounceOutRight">
                    <div v-if="paso === 'datos_cliente'" class="card-inner p-3">
                      <div class="form-group">
                        <div class="row mt-2">
                          <div class="col-sm-6">
                            <label class="form-label">Nombre(s)</label>
                            <input class="form-control" type="text" placeholder="" v-model="cliente.nombre" required>
                          </div>
                          <div class="col-sm-6">
                            <label class="form-label">Apellido(s)</label>
                            <input class="form-control" type="text" placeholder="" v-model="cliente.apellidos" required>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-sm-6">
                            <label class="form-label">Correo Electrónico</label>
                            <input class="form-control" type="email" placeholder="" v-model="cliente.email" required>
                          </div>
                          <div class="col-sm-6">
                            <label class="form-label">Teléfono</label>
                            <input ref="input_tel" type="tel" class="form-control" v-model="cliente.telefono" required>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-sm-6">
                            <label class="form-label">Calle</label>
                            <input class="form-control" type="text" placeholder="" v-model="cliente.calle" required>
                          </div>
                          <div class="col-sm-6">
                            <label class="form-label">Número</label>
                            <input class="form-control" type="text" placeholder="Incluir número interior si existe" v-model="cliente.numero" required>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-sm-6">
                            <label class="form-label">Colonia</label>
                            <input class="form-control" type="text" placeholder="" v-model="cliente.colonia" required>
                          </div>
                          <div class="col-sm-6">
                            <label class="form-label">Ciudad</label>
                            <input class="form-control" type="text" placeholder="" v-model="cliente.ciudad" required>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-sm-6">
                            <label class="form-label">Estado</label>
                            <input class="form-control" type="text" placeholder="" v-model="cliente.estado" required>
                          </div>
                          <div class="col-sm-6">
                            <label class="form-label">Código Postal</label>
                            <input class="form-control" type="text" placeholder="" v-model="cliente.cp" required>
                          </div>
                        </div>
                        <div class="row border-top pt-3 mt-4">
                          <div class="col-7 text-right border-right">
                            <span class="form-note">Transacciones realizadas vía</span>
                            <img src="@/assets/images/openpay/openpay.png" alt="OpenPay" />
                          </div>
                          <div class="col-5 d-flex align-items-center">
                            <img class="mr-2" src="@/assets/images/openpay/security.png" alt="SSL" />
                            <span class="form-note">Tus pagos se realizan de forma segura con encriptación de 256 bits</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                  <transition name="slide-fade"
                              enter-active-class="animate__animated animate__bounceInLeft"
                              leave-active-class="animate__animated animate__bounceOutLeft">
                    <div v-if="paso === 'pago'" class="card-inner p-3">
                      <div class="form-group">
                        <div class="row">
                          <div class="credit col-sm-4 border-right mb-2">
                            <span class="preview-title-lg overline-title">Tarjetas de crédito</span>
                            <img src="@/assets/images/openpay/cards1.png" alt="Tarjetas de Crédito" />
                          </div>
                          <div class="debit col-sm-8">
                            <span class="preview-title-lg overline-title">Tarjetas de débito</span>
                            <img src="@/assets/images/openpay/cards2.png" alt="Tarjetas de Débito" />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-sm-6 mb-2">
                            <label class="form-label">Nombre del titular</label>
                            <input class="form-control card-number" name="card-number" type="text" placeholder="Como aparece en la tarjeta" autocomplete="off" v-model="tarjeta.holderName" data-openpay-card="holder_name" required>
                            <em v-if="tarjeta.holderName.length && !valid.holderName" class="text-danger fs-12px text-left w-100 d-inline-block">El nombre del titular es inválido</em>
                          </div>
                          <div class="col-sm-6">
                            <label class="form-label">Número de tarjeta</label>
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni" :class="cc_class"></em>
                              </div>
                              <input type="tel" v-maska="'#*'" class="form-control" autocomplete="off" data-openpay-card="card_number" v-model.number="tarjeta.cc" :maxlength="(card.card) ? Math.min(...card.card.lengths) : 15" required>
                            </div>
                            <em v-if="tarjeta.cc.length && !valid.card" class="text-danger fs-12px text-left w-100 d-inline-block">El número de tarjeta es inválido</em>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-6">
                            <label class="form-label">Fecha de expiración</label>
                            <div class="row">
                              <div class="col-6">
                                <input class="form-control" v-maska="'#*'" type="tel" maxlength="2" placeholder="Mes" v-model="tarjeta.month" data-openpay-card="expiration_month" required>
                              </div>
                              <div class="col-6">
                                <input class="form-control" v-maska="'#*'" type="tel" maxlength="2" placeholder="Año" v-model="tarjeta.year" data-openpay-card="expiration_year" required>
                              </div>
                            </div>
                            <em v-if="tarjeta.month.length && tarjeta.year.length && !valid.expiration" class="text-danger fs-12px text-left w-100 d-inline-block">La fecha de expiración es inválida</em>
                          </div>
                          <div class="col-6">
                            <label class="form-label">Código de seguridad</label>
                            <div class="row">
                              <div class="col-8 col-sm-6">
                                <input class="form-control" v-maska="'#*'" type="tel" :placeholder="(card.card) ? (card.card.code.size + ' dígitos') : '3 o 4 dígitos'" v-model="tarjeta.cvv" :maxlength="cvv_len" autocomplete="off" data-openpay-card="cvv2" required>
                              </div>
                              <div class="col-4 col-sm-6 p-0 d-flex align-items-center">
                                <img :src="require('@/assets/images/openpay/cvv-'+cvv_len+'.png')" alt="CVV2" />
                              </div>
                            </div>
                            <em v-if="tarjeta.cvv.length && !valid.cvv" class="text-danger fs-12px text-left w-100 d-inline-block">El código CVV es inválido</em>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-12">
                            <label class="form-label">Número de Pagos (Meses con Intereses)</label>
                            <select v-model="tarjeta.meses" class="form-control">
                              <option :value="1">Pago único</option>
                              <option v-for="mes in [3,6,9,12]" :key="mes" :value="mes">{{mes}} Pagos</option>
                            </select>
                          </div>
                        </div>
                        <div class="row border-top pt-3 mt-4">
                          <div class="col-7 text-right border-right">
                            <span class="form-note">Transacciones realizadas vía</span>
                            <img src="@/assets/images/openpay/openpay.png" alt="OpenPay" />
                          </div>
                          <div class="col-5 d-flex align-items-center">
                            <img class="mr-2" src="@/assets/images/openpay/security.png" alt="SSL" />
                            <span class="form-note">Tus pagos se realizan de forma segura con encriptación de 256 bits</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div><!-- .col -->
              <div class="col-xl-5 col-lg-5">
                <div class="card card-bordered ml-lg-4 ml-xl-0">
                  <div class="nk-iv-wg4">
                    <div class="nk-iv-wg4-sub">
                      <h6 class="nk-iv-wg4-title title">Detalles del Curso</h6>
                    </div><!-- .nk-iv-wg4-sub -->
                    <div class="nk-iv-wg4-sub">
                      <ul class="nk-iv-wg4-list">
                        <li>
                          <div class="sub-text">Descripción</div>
                          <div class="lead-text"><a class="text-blue" target="_blank" :href="model.pdf" title="Ver PDF">Ver PDF</a></div>
                        </li>
                      </ul>
                    </div><!-- .nk-iv-wg4-sub -->
                    <div class="nk-iv-wg4-sub">
                      <ul class="nk-iv-wg4-list">
                        <li>
                          <div class="sub-text">Forma de Pago</div>
                          <div class="lead-text">Tarjeta de Débito/Crédito</div>
                        </li>
                      </ul>
                    </div><!-- .nk-iv-wg4-sub -->
                    <div class="nk-iv-wg4-sub">
                      <ul class="nk-iv-wg4-list">
                        <li>
                          <div class="sub-text">Subtotal</div>
                          <div class="lead-text">{{$filters.money(model.costo_mxn)}}</div>
                        </li>
                      </ul>
                    </div><!-- .nk-iv-wg4-sub -->
                    <div class="nk-iv-wg4-sub">
                      <ul class="nk-iv-wg4-list">
                        <li>
                          <div class="sub-text">
                            <span>Cargos por servicio ({{comision_pago.label}})</span>
                          </div>
                          <div class="lead-text pt-1">{{$filters.money(model.comision)}}</div>
                        </li>
                        <!--
                        <li>
                          <div class="sub-text">
                            <span>Costo de envío</span>
                          </div>
                          <div class="lead-text pt-1"><a class="text-blue" target="_blank" href="https://www.dhl.com/mx-es/home/obtenga-una-cotizacion/one-time-cotizacion-de-envios.html" title="Cotizar Envío">Cotizar Envío</a></div>
                        </li>
                        -->
                      </ul>
                    </div><!-- .nk-iv-wg4-sub -->
                    <div class="nk-iv-wg4-sub">
                      <ul class="nk-iv-wg4-list">
                        <li>
                          <div class="lead-text">Total</div>
                          <div class="caption-text font-weight-bold">{{$filters.money(model.total)}} {{model.moneda}}</div>
                        </li>
                      </ul>
                    </div><!-- .nk-iv-wg4-sub -->
                    <div class="nk-iv-wg4-sub text-center bg-lighter">
                      <button type="submit" class="btn btn-block btn-lg btn-primary" v-if="paso === 'datos_cliente'">CONTINUAR</button>
                      <button type="submit" class="btn btn-block btn-lg btn-success" v-else :disabled="!valid.global">PAGAR</button>
                    </div><!-- .nk-iv-wg4-sub -->
                  </div><!-- .nk-iv-wg4 -->
                </div><!-- .card -->
              </div><!-- .col -->
            </div><!-- .row -->
          </form><!-- .nk-block -->
        </div>
      </div>
    </div>
  </div>
  <!-- content @e -->
  <!-- footer @s -->
  <div class="nk-footer nk-footer-fluid bg-white text-dark">
    <div class="container-xl wide-lg">
      <div class="nk-footer-wrap">
        <div class="col-12 text-center mb-3">
          Consulta nuestro <a href="/assets/pdf/aviso-privacidad.pdf" class="text-info" target="_blank">aviso de privacidad</a> y nuestros <a href="/assets/pdf/terminos-y-condiciones.pdf" class="text-info" target="_blank">términos y condiciones</a>
        </div>
        <div class="col-12 text-center">
          © {{year}} | Hecho con <i class="ni ni-coffee-fill"></i> y <i class="ni ni-heart-fill text-danger"></i> en <a href="https://tekoestudio.com" class="text-info" target="_blank">Teko Estudio</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, computed, onMounted, watchEffect} from 'vue';
import {APP_URL, COMISION_PAGO, IVA} from "@/providers/config";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import {useRoute, useRouter} from "vue-router";
import card_validator from 'card-validator';
import intlTelInput from "intl-tel-input";
import * as uuid from 'uuid';
export default defineComponent({
  name: "FichaPago",
  components: {},
  setup() {
    //Dependencias
    const { loading, make } = useApi();
    const router = useRouter();
    const {params, query} = useRoute();
    const input_tarjeta = ref(null);
    const paso = ref('datos_cliente');
    //Data
    const model = ref({
      uuid_pago: '',
      id_curso: 0,
      slug: '',
      titulo: '',
      portada: '',
      descripcion: '',
      pdf: '',
      costo: 0,
      costo_mxn: 0,
      envio: 0,
      comision: 0,
      total: 0,
      moneda: 'MXN',
      token: '',
      device_session_id: '',
      redirect_url: ''
    });

    const tarjeta = ref({
      holderName: '',
      cc: '',
      month: '',
      year: '',
      cvv: '',
      meses: 1
    });

    const cliente = ref({
      nombre: '',
      apellidos: '',
      email: '',
      calle: '',
      numero: '',
      colonia: '',
      ciudad: '',
      estado: '',
      telefono: '',
      pais: '',
      cp: ''
    });

    const cargo = ref({

    });

    const input_tel = ref(null);

    const uuid_pago = uuid.v4();

    const year = ref(new Date().getFullYear());

    //Computed y Watched
    const comision_pago = computed(() => {
      return COMISION_PAGO.meses[tarjeta.value.meses];
    });

    watchEffect(() => {
      let comision = comision_pago.value.fija + (model.value.costo_mxn * comision_pago.value.variable);
      model.value.comision = comision + (comision * IVA);
      model.value.comision = Number(model.value.comision.toFixed(2));
      model.value.total = model.value.costo_mxn + model.value.comision;
    });

    const card = computed(() => {
      return card_validator.number(tarjeta.value.cc);
    });

    const cvv_len = computed(() => {
      return (card.value.card) ? card.value.card.code.size : 3;
    })

    const valid = computed(() => {
      let {holderName, month, year, cvv} = tarjeta.value;
      let state = {holderName: card_validator.cardholderName(holderName).isValid, card: card.value.isValid, expiration: card_validator.expirationDate({month, year}).isValid, cvv: card_validator.cvv(cvv, cvv_len.value).isValid};
      state.global = (state.holderName && state.card && state.expiration && state.cvv);
      return state;
    });

    const cc_class = computed(() => {
      if(card.value.isPotentiallyValid && card.value.card){
        card.value.card.type = (card.value.card.type === 'mastercard') ? 'master-card' : card.value.card.type;
        return 'ni-'+card.value.card.type;
      } else if(card.value.isPotentiallyValid) {
        return 'ni-cc';
      } else {
        return 'text-danger ni-cc-off';
      }
    });

    //Methods
    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('cursos/get/'+params.slug, {});
      if(data === null){
        router.push('/');
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.push('/');
          Swal.fire('Error', data.message, 'error');
        } else {
          model.value = data.curso;
          if(query.id){
            getCargo();
          }
        }
      }
    }

    async function getCargo() {
      loading.message = 'Obteniendo información';
      let {data} = await make('cursos/get-cargo/'+query.id, {});
      if(data !== null) {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          cargo.value = data.cargo;
          if(data.cargo.estatus === 'Pendiente'){
            setTimeout(() => {
              router.go();
            }, 10000);
          }
        }
      }
    }

    function setPaso(_paso){
      paso.value = '';
      setTimeout(() => {
        paso.value = _paso;
      }, 500);
    }

    async function save(){
      if(paso.value === 'datos_cliente') {
        setPaso('pago');
        return;
      }
      if(!valid.value.global){
        return;
      }
      loading.message = 'Validando pago';
      loading.active = true;
      let {holderName, cc, month, year, cvv} = tarjeta.value;
      window.OpenPay.token.create({
        "holder_name": holderName,
        "card_number": cc,
        "expiration_month": month,
        "expiration_year": year,
        "cvv2": cvv,
      }, async (response) => {
        model.value.token = response.data.id;
        model.value.device_session_id = window.OpenPay.deviceData.setup();
        model.value.redirect_url = APP_URL + '/curso/' + model.value.slug;
        model.value.meses = tarjeta.value.meses;
        model.value.uuid_pago = uuid_pago;
        cliente.value.telefono = window.iti.getNumber();
        cliente.value.pais = window.iti.getSelectedCountryData().name;
        let {data} = await make('cursos/make-payment', {model: model.value, cliente: cliente.value});
        if(data === null){
          Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
        } else {
          if(data.error){
            Swal.fire('Error', data.message, 'error');
          } else {
            Swal.fire('Éxito', data.message, 'success').then(() => {
              if(data.redirect_url){
                location.href = data.redirect_url;
              } else {
                router.go();
              }
            });
          }
        }
      }, (response) => {
        loading.active = false;
        Swal.fire('Error', response.message, 'error');
      });
    }

    //Mounted
    onMounted(() => {
      window.iti = intlTelInput(input_tel.value, {
        initialCountry: 'mx',
        nationalMode: true,
        utilsScript: require('intl-tel-input/build/js/utils.js')
      });
      get();
    });

    return { model, cliente, input_tel, year, paso, valid, input_tarjeta, tarjeta, card, cc_class, cvv_len, comision_pago, cargo, save, setPaso }
  }
});
</script>

<style>
.logo {
  max-width: 350px;
  display: block;
  margin: 3em auto 1em auto;
}
body.bg-lighter {
  background-color: rgba(207, 178, 200, 0.82) !important;
}
.form-icon .icon {
  font-size: 2em;
}
.icon.ni-american-express, .icon.ni-visa {
  color: #0a2d6c;
}
.icon.ni-master-card {
  background: linear-gradient(to right, red, yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-js {
  overflow-x: hidden;
}

h4, h6, .overline-title, .btn {
  font-weight: 500;
}

.text-primary {
  color: #000000 !important;
}

.btn-primary {
  background: #b488a9 !important;
  border-color: #b488a9 !important;
  color: #FFFFFF !important;
  font-weight: normal;
}

.btn-primary:hover {
  background: #a06992 !important;
  border-color: #a06992 !important;
}

.btn-outline-primary {
  border: 0.5px solid #888888 !important;
  color: #333333 !important;
  font-weight: normal;
}

.btn-outline-primary:hover {
  background: #333333 !important;
  color: #FFFFFF !important;
  font-weight: normal;
}

.btn-outline-primary:hover em {
  color: #FFFFFF !important;
}
</style>